import React, { Component } from 'react'


class MapContainer extends Component {
  render() {
    return (
      <div className="map-content">

        <iframe title="mapSoy502" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d286.97373555480283!2d-90.5443030291989!3d14.596609311506855!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a16b521b6915%3A0x2f4e832885c911ac!2sSoy502!5e0!3m2!1ses-419!2sgt!4v1565299417714!5m2!1ses-419!2sgt" width="100%" frameBorder="0" allowFullScreen></iframe>
      </div>
    );
  }
}

export default MapContainer
