import React, {Component} from 'react'
import Landing from './Landing.js';

class ContentF extends Component {
  render () {
    return (
      <div>
        <Landing />
      </div>
    );
  }
}

export default ContentF
