import React, { Component } from 'react'
import logo from '../images/cf-logo-blanco.png'
import Menu from './Menu.js'
import { showMenu } from './scripts.js'

class Header extends Component {
  render(){
    return(
      <header>
        <div id="Header" className="header">
          <div className="inner">
            <div className="logo">
              <a href="/"><img src={logo} className="App-logo" alt="logo-content-factory"/></a>
            </div>
            <div className="tools">
              <ul>
                <li><button onClick={showMenu}><span className="menubars"></span></button></li>
              </ul>
            </div>
            <Menu />
          </div>
        </div>
      </header>
    );
  }
}

export default Header
