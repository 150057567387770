import React, { Component } from 'react'

class Footer extends Component {
  render(){
    return(
      <div id="Footer" className="footer">
        <div className="content-footer">
          <h3>ContentFactory &copy; 2020</h3>
        </div>
      </div>
    );
  }
}
 export default Footer
