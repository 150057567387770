import React, { Component } from 'react'
/* import { Link } from 'react-router-dom' */
import CardFlip from './CardFlip.js'

class Services extends Component {
  render() {
    return (
      <div id="Services" className="services-content">
        <div className="inner">
          <h2 className="title-section">Nuestros Servicios</h2>
          <div className="row">
            <div className="item-service">
              <CardFlip
                frontSide={
                  <div className="content-item">
                    <p><span className="branded-icon video-factory"></span></p>
                    <h4><span className="bold-text">Video</span> Factory</h4>
                    <ul>
                      <li>Institucional</li>
                      <li>Documental</li>
                      <li>Spot publicitario</li>
                      <li>Story Telling</li>
                      <li>After Movie</li>
                      <li>Eventos Empresariales</li>
                    </ul>
                  </div>
                }
                backSide={
                  <div className="content-item">
                    <p><span className="branded-icon video-factory"></span></p>
                    <h4><span className="bold-text">Video</span> Factory</h4>
                    <p>Sabemos cómo lograr que tu marca realmente destaque con una producción de alta calidad.</p>
                    <p>En la actualidad, el video es la norma para contar una historia que impacte, que llame la atención de tus clientes.</p>
                  </div>
                }
              />
            </div>
            <div className="item-service">
              <CardFlip
                frontSide={
                  <div className="content-item">
                    <p><span className="branded-icon photo"></span></p>
                    <h4><span className="bold-text">Photo</span> Factory</h4>
                    <ul>
                      <li>Publicitaria</li>
                      <li>Eventos Empresariales</li>
                      <li>Documental</li>
                    </ul>
                  </div>
                }
                backSide={
                  <div className="content-item">
                    <p><span className="branded-icon photo"></span></p>
                    <h4><span className="bold-text">Photo</span> Factory</h4>
                    <p>Sabemos que una imagen vale más que mil palabras.</p>
                    <p>Te ofrecemos un servicio fotográfico publicitario de primer nivel: producción, edición, retoque y colorización final. </p>
                  </div>
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="item-service">
              <CardFlip
                frontSide={
                  <div className="content-item">
                    <p><span className="branded-icon video-drone"></span></p>
                    <h4><span className="bold-text">Video</span> Drone</h4>
                    <ul>
                      <li>Foto y video aéreo</li>
                    </ul>
                  </div>
                }
                backSide={
                  <div className="content-item">
                    <p><span className="branded-icon video-drone"></span></p>
                    <h4><span className="bold-text">Video</span> Drone</h4>
                    <p>¡Qué te miren desde arriba!</p>
                    <p>Somos expertos en tomas aéreas que realzan tu marca.</p>
                  </div>
                }
              />
            </div>
            <div className="item-service">
              <CardFlip
                frontSide={
                  <div className="content-item">
                    <p><span className="branded-icon web-dev"></span></p>
                    <h4><span className="bold-text">Web</span> Dev</h4>
                    {/*  */}
                    <ul>
                      <li>Landing Pages</li>
                      <li>E-Commerce</li>
                      <li>Catálogo de productos en línea</li>
                      <li>Migración a la Nube</li>
                      <li>Desarrollo de AppWeb</li>
                    </ul>
                  </div>
                }
                backSide={
                  <div className="content-item">
                    <p><span className="branded-icon web-dev"></span></p>
                    <h4><span className="bold-text">Web</span> Dev</h4>
                    <p>Diseñamos y desarrollamos sitios web persuasivos.</p>
                    <p>Adaptadas a la imagen de tu empresa o entidad, que combinen la creatividad con la usabilidad y funcionalidad.</p>
                  </div>
                }
              />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default Services
