import React, { Component } from 'react';

class About extends Component {
  render(){
    return(
      <div id="About" className="about-section">
        <div className="inner">
          <div className="float-text">
            <h2>¿Quiénes somos?</h2>
            <p>
              Más que una productora, somos un equipo creativo enfocado en desarrollar proyectos de marketing digital, con presencia en el mercado guatemalteco desde hace 6 años. 
              Nuestro objetivo es crear contenido dinámico e innovador para tu empresa y así reforzar la imagen de tu marca en el mundo digital. Al final, si algo sabemos es contar historias y transmitir experiencias por medio de obras audiovisuales.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About
