import React, { Component } from 'react'
import { Link, animateScroll as scroll } from "react-scroll"
import { showMenu } from './scripts.js'

class Menu extends Component{
  scrollToTop = () => {
     scroll.scrollToTop();
   };
  render(){
    return(
      <div className="menu-expandible leftMenu">
        <ul>
          <li className="link-item">
            <Link className="link-menu" activeClass="active" to="Cover" spy={true} smooth={true} offset={-120} duration={500} onClick={showMenu}>
              Portada
            </Link>
          </li>
          <li className="link-item">
            <Link className="link-menu" activeClass="active" to="Services" spy={true} smooth={true} offset={-120} duration={500} onClick={showMenu}>
              Servicios
            </Link>
          </li>
          <li className="link-item">
            <Link className="link-menu" activeClass="active" to="About" spy={true} smooth={true} offset={-120} duration={500} onClick={showMenu}>
              Nosotros
            </Link>
          </li>
          <li className="link-item">
            <Link className="link-menu" activeClass="active" to="Contact" spy={true} smooth={true} offset={-120} duration={500} onClick={showMenu}>
              Contacto
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default Menu
