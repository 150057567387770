import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import './css/styles.css'
import ContentF from './includes/ContentF'
import * as serviceWorker from './serviceWorker'

class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <ContentF />
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
