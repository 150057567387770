import React, { Component } from 'react';
import Header from './Header.js'
import Slide from './Slide.js'
import Services from './Services.js'
import About from './About.js'
import Testimonials from './Testimonials.js'
//import Thinking from './Thinking.js'
import Contact from './Contact.js'
import Footer from './Footer.js'

class Landing extends Component {
  render(){
    return(
        <div>
          <Header />
          <Slide />
          <Services />
          <About />
          <Testimonials />
          {/* <Thinking /> */}
          <Contact />
          <Footer />
        </div>
    );
  }
}

export default Landing
