import React, { Component } from 'react'
import MapContainer from './MapContainer.js'
import $ from "jquery"

class Contact extends Component {
  constructor(){
    super();

    this.state = {
      name: '',
      email: '',
      subject: '',
      phone: '',
      message: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange = e => {
      this.setState({[e.target.name]: e.target.value})
  }

  handleSubmit(e){
    e.preventDefault();
    const tokenApiClient = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDI3ODI2MTMsImF1ZCI6ImRjZDNmOTc4YmFjMTJkOTUwOWZmMmY3MmQwNGQzMDg2YTlkYWUwODciLCJkYXRhIjp7ImlkIjoiY29udGVudGZhY3RvcnkiLCJuYW1lIjoiQ29udGVudCBGYWN0b3J5IiwiZ3JvdXAiOiJjb250ZW50ZmFjdG9yeSJ9fQ.bBygFTOcDdWjse-nK2BkanxkLtH4nGP0v6IPZvVNbfE";
    const url_apiContact = "https://api.magnet.gt/v1/process/new_landing_contact.php";
    var { name, email, subject, phone, message } = this.state

    if (subject === "") {
      subject = "VideoFactory";
    }
    const settings = {
      "url": url_apiContact,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/json",
        "Authorization": tokenApiClient
      },
      "data": JSON.stringify({"name":name,"email":email,"subject":subject,"message":message,"phone":phone}),
    };
    $.ajax(settings).done(function (response, var1, var2) {
      if (var2.status === 201 && response.success === true) {
        var inputContact = document.querySelectorAll(".inputContact");
        if (inputContact.length > 0) {
          for (var i = 0; i < inputContact.length; i++) {
            inputContact[i].value = "";
          }
        }
      } else {

      }
    });
  }

  render(){
    return(
      <div id="Contact" className="contact-section">
        <div className="inner">
          <h2 className="title-section">Contacto</h2>
          <div className="form-contacto">
            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="item-input">
                  <input className="inputContact" type="text" name="name" id="name" onChange={this.handleChange} placeholder="Nombre" required />
                </div>
                <div className="item-input">
                  <input className="inputContact" type="email" placeholder="Email" onChange={this.handleChange} name="email" required />
                </div>
                <div className="item-input">
                  {/* <label>Asunto <span>*</span></label> */}
                  <select id="subject" name="subject" onChange={this.handleChange}>
                    <option value="" disabled defaultValue>Asunto</option>
                    <option value="VideoFactory">Video Factory</option>
                    <option value="PhotoFactory">Photo Factory</option>
                    <option value="VideoDrone">Video Drone</option>
                    <option value="WebDev">Web Dev</option>
                  </select>
                </div>
                <div className="item-input">
                  {/* <label>Teléfono <span>*</span></label> */}
                  <input className="inputContact" type="text" name="phone" id="phone" placeholder="Teléfono" onChange={this.handleChange} required/>
                </div>
                <div className="item-input complete-input">
                  <textarea className="inputContact" rows="6" cols="50" name="message" id="message" onChange={this.handleChange} placeholder="Mensaje" required></textarea>
                </div>
                <input className="send-btn" type="submit" value="Enviar" />
             </form>
          </div>
          <MapContainer />
        </div>
      </div>
    );
  }
}

export default Contact
