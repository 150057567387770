import React, { Component } from 'react'
import {BrowserView, MobileView} from "react-device-detect"
import SlideDesktop from './SlideDesktop.js'
import SlideMobile from './SlideMobile.js'

class Slide extends Component {
  render(){
    return(
      <div id="Cover" className="slide-content">
        <BrowserView>
          <SlideDesktop />
        </BrowserView>
        <MobileView>
          <SlideMobile />
        </MobileView>
      </div>
    );
  }
}

export default Slide
