import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img1Mobile from '../images/img1Mobile.jpg'
import img2Mobile from '../images/img2Mobile.jpg'
import img3Mobile from '../images/img3Mobile.jpg'
import img4Mobile from '../images/img4Mobile.jpg'

class SlideMobile extends Component {
  render() {
    return (
      <Carousel autoPlay useKeyboardArrows showThumbs={false} showStatus={false} infiniteLoop={true}>
        <div>
          <img src={img1Mobile} alt="Content Factory img1" />
        </div>
        <div>
          <img src={img2Mobile} alt="Content Factory img2" />
        </div>
        <div>
          <img src={img3Mobile} alt="Content Factory img3" />
        </div>
        <div>
          <img src={img4Mobile} alt="Content Factory img4" />
        </div>
      </Carousel>
    );
  }
}

export default SlideMobile
