import React, { Component } from 'react'


class Testimonials extends Component {
  render(){
    return(
      <div id="Testimonials" className="testimonials-section">
        <div className="inner">
          <div className="text-comment">
            <span className="quote">“</span>
              Si puede ser escrito, o pensado, puede ser filmado.
            <span className="quote">”</span>
            <div className="author">
              <div className="line-inclined"></div>
              <span className="author-name">Stanley Kubrick</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonials
