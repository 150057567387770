import React, { Component } from 'react'
import Flippy, { FrontSide, BackSide } from 'react-flippy';

class CardFlip extends Component {

  render(){
    return(
      <Flippy
        flipOnHover={true} // default false
        flipOnClick={false} // default false
        flipDirection="horizontal" // horizontal or vertical
        ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
        // if you pass isFlipped prop component will be controlled component.
        // and other props, which will go to div
        style={{ width: '100%', height: '100%' }} /// these are optional style, it is not necessary
      >
        <FrontSide>
          {this.props.frontSide}
        </FrontSide>
        <BackSide>
          {this.props.backSide}
        </BackSide>
      </Flippy>
    )
  }
}

export default CardFlip