import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'
import img4 from '../images/img4.jpg'

class SlideDesktop extends Component {
  render(){
    return(
        <Carousel showStatus={false} useKeyboardArrows showThumbs={false} autoPlay infiniteLoop={true}>
          <div>
              <img src={img1} alt="Content Factory img1"/>
          </div>
          <div>
              <img src={img2} alt="Content Factory img2"/>
          </div>
          <div>
              <img src={img3} alt="Content Factory img4"/>
          </div>
          <div>
              <img src={img4} alt="Content Factory img4"/>
          </div>
        </Carousel>
    );
  }
}

export default SlideDesktop
